.page-content.inverted {
  background-color: $invertedBg;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  label {
    color: $textLight;
  }

  a {
    color: $invertedLink;

    &:hover {
      color: $invertedLinkHover;
    }
  }
}
