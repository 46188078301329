.fourohfour-container-bg {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  div {
    border-radius: 16px;
    align-self: center;
    max-width: 700px;
    padding: 16px;
  }

  h1 {
    font-size: 120px;
    margin: 0;
    padding: 0;
  }
}
