.savesearchbutton {
  display: inline;
  color: #fcc536;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: #82702e;
  }
}
