@import "../../assets/styles/include";

// .page-wrapper {
//   display: flex;
//   height: 0;
//   min-height: 100vh;
//   flex-direction: column;
//   .page-content {
//     flex: 1 1 auto;
//     height: 0;
//     min-height: calc(100% - 163px);
//   }
// }

html,
body {
  height: 100%;
}

.page-wrapper {
  height: 100vh;

  .page-content-map {
    height: calc(100% - #{$height-navandfilter-desktop});
  }

  .page-content {
    min-height: calc(100% - #{$height-navbar-desktop});
  }
}
