$yellow: #ffc738;

.paywall {
  text-align: center;

  .paywall-header {
    background-image: url("../../assets/images/buildings.jpg");
    background-size: cover;
    padding: 64px 0 56px 0;
    overflow: hidden;
  }

  .paywall-body {
    background: $yellow;
    padding-left: 48px;
    padding-right: 48px;
    overflow: hidden;

    p {
      font-size: 14px;
    }

    h2 {
      line-height: 1.51;
    }

    button {
      margin-top: 12px;
      border-radius: 26px;
      border: solid 1px #1b1c1d;
      background-color: #1b1c1d;
      color: #ffffff;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.71;
      letter-spacing: 0.4px;
      text-align: center;
      color: #ffffff;
      padding: 5px 15px;
    }

    a {
      line-height: 1.6;
      color: #1f1c1c;
    }
  }
}
