.login {
}

h1 {
  color: #ffc738;
  font-weight: 500;
}

.form-bottomtext {
  font-size: 15px;
  line-height: 30px;
  color: #eee;
  a {
    text-decoration: none;
  }
}
