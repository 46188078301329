@import "../../assets/styles/include";

.spinneronmap {
  background: rgba(253, 197, 55, 0.4);
  padding: 8px;
  position: absolute;
  border-radius: 50%;
  z-index: 501;
  @include tabletandsmaller() {
    bottom: 75px;
    left: 5px;
  }
  bottom: 32px;
  left: 32px;
}
