.btn {
  font-size: 16px;
  line-height: 1.56;
  border-radius: 2px;
  padding: 8px 32px;
  opacity: 1;
  transition: 500ms opacity;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    transition: 500ms opacity;
    opacity: 0.4;
  }
}

@mixin btncol($background, $color) {
  background: $background;
  border: none;
  color: $color;
  &:hover {
    background: lighten($background, 10);
    transition: 300ms background;
  }
}


.btn-primary {
  @include btncol($primaryDark, $textDark);
}

.btn-warning {
  @include btncol(#9b0008, $textLight);
}



.btn-secondary {
  background: $secondaryDark;
  border: none;
  color: $textLight;
  &:hover {
    background: lighten($secondaryDark, 10);
    transition: 300ms background;
  }
}

.btn-tertiary {
  background: $tertiaryDark;
  border: none;
  color: $textLight;
  &:hover {
    background: lighten($tertiaryDark, 10);
    transition: 300ms background;
  }
}


.btn-fullwidth {
  width: 100%;
}

.btn-autowidth{
  width: fit-content;
}
