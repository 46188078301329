@import "../../assets/styles/include";

$linecolor: #888;
$textDark: #1f1c1c;
$topBgcolor: #f9f9f9;

.rpanefeatureinfo {
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;

  .rpane-content {
    background: white;
  }

  .rpane-label {
    color: black;
    background: $secondary;
    padding: 4px;
  }

  .box-shadow-left {
    box-shadow: -10px 0px 10px 1px #0002;
  }

  .fi-header-single-heading {
    background: $topBgcolor;
    color: $textDark;
    padding: 32px 16px;
    font-size: 20px;
    font-weight: bold;

    .larger-text {
      font-size: 24px;
      font-weight: 500;
    }

    .smaller-text {
      margin-top: 8px;
      font-size: 14px;

      &.lite {
        font-weight: normal;
      }

      a {
        color: blue;
      }
    }

    .app-label {
      margin-bottom: 8px;
    }
  }

  .fi-header-paywall {
    //margin-top: 64px;
    padding: 8px;
  }

  .fi-header {
    background: $topBgcolor;
    color: $textDark;
    display: flex;
    flex-direction: row;

    & > div {
      flex-grow: 1;
      flex-basis: 0;
      justify-content: center;
      em {
        font-style: normal;
        font-size: 40px;
        font-weight: bold;
        display: block;
      }

      padding: 16px;
    }
  }
  .fi-header-savesearch {
    background: $topBgcolor;
    padding: 0 16px 16px 16px;
    width: 100%;
  }
  .fi-content {
    font-size: 15px;
    padding: 16px;
    h2 {
      padding-bottom: 12px;
    }

    .fi-content-labdec {
      margin-bottom: 4px;
      span:nth-child(1) {
        display: table-cell;
        min-width: 70px;
      }
      span:nth-child(2) {
        display: table-cell;
      }
    }

    .fi-content-line {
      font-size: 15px;
      margin-bottom: 4px;
    }
  }

  .fi-list {
    &.no-hover {
      .fi-list-item {
        &:hover {
          cursor: initial;
          background: darken($color: #fff, $amount: 5%);
          .fli-marker {
            color: #537594;
          }
        }
      }
    }

    .fi-noprops {
      text-align: center;
      padding-top: 64px;
    }

    .fi-list-item {
      background: #fff;
      color: $textDark;
      line-height: 1.33em;

      &:hover {
        cursor: pointer;
        background: darken($color: #fff, $amount: 5%);
        .fli-marker {
          color: #537594;
        }
      }

      &.fi-active {
        background: white;
        .fli-marker {
          color: #537594;
        }
      }

      display: table;
      width: 100%;

      & > div {
        display: table-cell;
        vertical-align: middle;
      }
      .fli-marker {
        text-align: center;
        width: 20%;
        color: $textDark;
        svg {
          width: 16px;
          height: auto;
        }
      }
      .fli-info {
        width: 60%;
        border-bottom: 2px solid #e4e4e4;
        padding: 16px 0;

        .fli-location {
          font-size: 14px;
          margin-bottom: 4px;
          font-weight: 600;
        }

        .fli-appcount {
          color: $textDark;
          font-size: 12px;
        }

        .fli-savedsearches {
          .ss-view {
            font-size: 14px;
            font-weight: 500;
            color: $secondary;
            cursor: pointer;
            &:hover {
              color: darken($secondary, 20%);
            }
          }

          .ss-remove {
            margin-left: 16px;
            font-size: 14px;
            font-weight: 500;
            color: $textDarkLight;
            cursor: pointer;
            &:hover {
              color: darken($textDarkLight, 20%);
            }
          }
        }
      }

      .fli-chev {
        width: 20%;
        text-align: right;
        padding-right: 16px;
        border-bottom: 2px solid #e4e4e4;
        svg {
          color: rgba(34, 34, 34, 0.75);
          opacity: 0.2;
          font-weight: lighter;
          width: 14px;
          height: auto;
        }
      }
    }
  }

  .paywall,
  .fi-list {
    //box-shadow: 0px 1px 12px 0 #ccc;
    //padding-bottom: calc(100vh - 134px);
    background: white;
  }
}

.rpanenavbutton {
  @include desktop() {
    //display: none;
  }
  position: relative;
  max-width: 80px;
  padding: 8px 16px 4px;
  left: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $topBgcolor;
  color: $textDark;
  cursor: pointer;
  &:hover {
    background: darken(#d8d8d8, 10%);
  }

  .rpanenavbutton-icon {
    display: table-cell;
    // font-size: 30px;
  }
  .rpanenavbutton-text {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    padding-left: 16px;
    font-size: 20px;
  }
}

.rpanenavbutton-desktop {
  position: relative;
  padding: 16px 0 0 16px;
  background: $topBgcolor;
  color: #ffc738;
  // color: $primaryDark;
  cursor: pointer;
  &:hover {
    color: #000;
  }

  .rpanenavbutton-icon {
    margin-right: 8px;
  }
  .rpanenavbutton-text {
    font-size: 15px;
  }
}

.pane-button {
  background: $secondary;
  color: black;
  border-radius: 24px;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  a {
    display: block;
    color: black;
    text-decoration: none;
  }
  margin-bottom: 8px;
}

.pane-button-invert {
  background: white;
  border: 2px solid $secondary;
  color: black;
  border-radius: 24px;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  a {
    display: block;
    color: black;
    text-decoration: none;
  }
  margin-bottom: 8px;
}
