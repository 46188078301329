._show-base {
  display: inherit;
}

.bcshow-fade-true {
  opacity: 1;
}

.bcshow-fade-false {
  opacity: 0;
}

.bcshow-blur-true {
  opacity: 1;
  filter: blur(0);
}

.bcshow-blur-false {
  opacity: 0;
  filter: blur(30px);
}

.bcshow-squash-true {
  opacity: 1;
  display: inline-block;
  transform: scaleY(1);
  transform-origin: top;
}

.bcshow-squash-false {
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  display: inline-block;
}
