$yellow: #ffc738;
$white: #ffffff;

h1.imbylogo {
  color: $yellow;
  font-weight: 500;

  .pro {
    color: $white;
    font-weight: 100;
  }
}
