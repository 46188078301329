@import "../../assets/styles/include";

.applicationlabel {
  display: inline-block;
  margin-right: 4px;
  background: $secondary;
  color: black;
  border-radius: 2px;
  font-size: 15px;
  font-weight: normal;
  padding: 4px 8px;
}
