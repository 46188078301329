.fs-error-container-bg {
  color: #333;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  h1,
  h2,
  h3,
  h4 {
    color: white;
  }

  div {
    border-radius: 16px;
    align-self: center;
    max-width: 700px;
    background: #fbf2fb;
    padding: 16px;
    border: 1px solid #edb1a8;
  }
}
