.payment {
  min-height: 100vh;
  background: #333333;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  label {
    color: #ffffff;
  }

  a {
    color: #efac1f;

    &:hover {
      color: #ffc738;
    }
  }

  h1 {
    color: #ffc738;
    font-weight: 500;
  }

  form {
    text-align: left;
    width: 310px;
    margin: 0 auto;
  }

  .header {
    background: #262626;

    h1 {
      color: #ffc738;
      span {
        color: #ffffff;
        font-weight: 300;
      }
    }
  }
}

.payment-box {
  padding: 15px;
  background-color: #1b1c1d;
  color: white;
  .payment-user {
    & > div {
      line-height: 23px;
    }
  }
  .payment-change-plan {
    font-size: 90%;
    margin-left: 8px;
  }
  strong {
    font-weight: 500;
  }
  button {
    max-width: 200px;
    margin-top: 20px;
    height: 48px;
    border-radius: 26px;
    background-color: #ffc738 !important;
    color: #333333;
    font-weight: 500;
    font-size: 16px;
  }
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;

  height: 56px;
  line-height: 56px;

  padding: 20px 12px;

  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #ffc738 !important;
}
