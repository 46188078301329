@import "../../../assets/styles/include";

.selectplan {
  min-height: 100vh;
  background: #333333;
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  label {
    color: #ffffff;
  }

  a {
    color: #efac1f;

    &:hover {
      color: #ffc738;
    }
  }

  h1 {
    color: #ffc738;
  }

  form {
    text-align: left;
    width: 310px;
    margin: 0 auto;

    button {
      width: 100%;
      border-radius: 26px;
      background-color: #ffc738 !important;
      font-weight: bold;
    }
  }

  .header {
    background: #262626;

    h1 {
      color: #fcc536;
      span {
        color: #ffffff;
        font-weight: 300;
      }
    }
  }
}

a.select-plan-button {
  color: #333333;
  text-decoration: none;
  &:hover {
    color: #333333;
  }
}
.select-plan-button {
  padding: 16px 48px;
  max-width: 500px;
  cursor: pointer;
  color: #333333;
  background: #ffc738;
  border-radius: 30px;
  width: 100%;
  border: 0;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    background: #cb9834;
  }
}

.narrow {
  max-width: 300px;
  margin-top: 15px;
}

.inclusions {
  background: $primary;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 30px !important;
  margin-top: 15px !important;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.inclusions h2 {
  padding-top: 0px;
  padding-bottom: 15px;
}

.inclusion {
  width: 100%;
}

.inclusion .fa-li {
  color: #ffc738;
}

.planpane {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 32px 16px;
  background: $primary;
  color: white;
  text-align: center;
  margin-bottom: 16px;
  height: calc(100% - 16px);
  .pp-img {
    margin-bottom: 16px;
  }
  .pp-price {
    font-size: 36px;
    .pprice {
      font-weight: bold;
    }
    .pmonth {
      font-size: 16px;
    }
  }

  .pp-name {
    margin: 16px 0 32px 0;
    color: #ffc738;
    font-size: 18px;
    font-weight: 500;
  }

  .pp-desc {
    flex-grow: 1;
    width: auto;
    padding-bottom: 16px;
    display: inline-block;
    font-size: 15px;
    text-align: left;
    .pp-descline {
      margin-bottom: 8px;
    }
    .fa-li {
      color: #ffc738;
    }
    ul {
      margin-left: 1.5em;
    }
  }

  .pp-button {
    width: 100%;
    align-self: flex-end;
  }
  border: 2px solid $primary;
  &.planpane-selected {
    border: 2px solid #ffc738;
    transition: 500ms all;
  }
}
