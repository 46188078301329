:root {
  --primary: #{$primary};
  --primaryLight: #{$primaryLight};
  --primaryDark: #{$primaryDark};
  --primaryGradient: #{$primaryGradient};
  --secondary: #{$secondary};
  --secondaryLight: #{$secondaryLight};
  --secondaryDark: #{$secondaryDark};
  --tertiary: #{$tertiary};
  --tertiaryLight: #{$tertiaryLight};
  --tertiaryDark: #{$tertiaryDark};
  --neutral: #{$neutral};
  --neutralLight: #{$neutralLight};
  --neutralLighter: #{$neutralLighter};
  --neutralLightest: #{$neutralLightest};
  --textLight: #{$textLight};
  --textDark: #{$textDark};
  --invertedBg: #{$invertedBg};
  --invertedLink: #{$invertedLink};
  --invertedLinkHover: #{$invertedLinkHover};
}
