@import "../../assets/styles/include";

.panelrightopener {
  position: absolute;
  cursor: pointer;
  z-index: 600;
  right: 0;
  top: 159px;

  @media screen and (min-width: 576px) {
    top: 124px;
  }

  .pro-container {
    position: relative;
    background: #000;
    color: white;
    padding: 8px 12px;
    font-size: 14px;

    &:hover {
      background: #555;
    }
  }

  &.pro-opened {
    opacity: 0;

    @include tabletandsmaller() {
      right: 0;
    }
  }

  &.pro-closed {
    right: 0;
  }
}
