* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  background: #f4f4f4;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {
  color: $primaryDark;
  padding: 0;
  margin: 0;
}

a.link {
  color: blue;
  text-decoration: underline;
}
