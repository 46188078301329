// heights

$height-navbar-desktop: 67px;
$height-filterbar-desktop: 48px;
$height-navandfilter-desktop: $height-filterbar-desktop+$height-navbar-desktop;


// colours

$textLight: #fff;
$textDark: #111111;
$textDarkLight: #666666;
$primary: #1b1c1d/* #262626*/;
$primaryLight: #333333;
$primaryDark: #da8a00;
$primaryGradient: linear-gradient(to right, #ff8e08, #ff6600);
$secondary: #fcc536;
$secondaryLight: #393c5d;
$secondaryDark: #393c5d;
$tertiary: #3970e6;
$tertiaryLight: #3970e6;
$tertiaryDark: #3970e6;
$neutral: #f9f9f9;
$neutralDark: #d8d8d8;
$neutralLight: #9c9dae;
$neutralLighter: #d8d8d8;
$neutralLightest: #f7f7f7;
$invertedBg: #333333;
$invertedLink: #efac1f;
$invertedLinkHover: #fcc536;
