@import "../../assets/styles/include";

.filterbar {
  background: black;
  padding: 8px 0;
  & > div > div {
    display: flex;
  }

  // @include tabletandsmaller() {
  //   & > div > div {
  //     display: flex;
  //     flex-wrap: wrap;

  //     .filterdropdown:last-child,
  //     .filterdropdown:nth-child(3) {
  //       margin-top: 8px;
  //     }
  //   }
  // }
}

.ddcompact {
  margin-bottom: 8px;
  .form-element-input {
    margin-bottom: 0;
  }
}

.partial-date-range {
  display: block;
}

ul.filterbar-nav {
  margin: 0 0 0 auto;
  list-style-type: none;
  padding: 0;
  text-align: right;

  @include tabletandsmaller() {
    text-align: left;
  }

  li {
    cursor: pointer;
    display: inline-block;
    list-style-type: none;
    margin-left: 15px;
    padding: 8px 8px;
    font-size: 14px;
    // font-weight: 500;
    color: rgba(255, 255, 255, 1);
    height: 32px;
    // background-color: #1b1c1d;
    &:hover {
      color: #ffc738;
      background-color: #1b1c1d;
    }

    @include mobile() {
      display: block;
      color: #000000;
    }

    .fbar-icon {
      // color: $invertedLinkHover;
      margin-right: 8px;
    }

    &:hover {
      color: $invertedLinkHover;
      .fbar-icon {
        color: $invertedLinkHover;
      }
    }
  }
}

.saved-searches-container {
  margin-left: auto;
  .save-this-search {
    cursor: pointer;

    font-size: 14px;
    font-weight: 500;
  }

  .saved-searches {
    cursor: pointer;
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    background: #1b1c1d;
    border-radius: 2px;
    padding-left: 8px;
    padding-right: 8px;
    &:hover {
      background: lighten(#252525, 10%);
    }

    .saved-searches-icon {
      color: $invertedLinkHover;
      margin-right: 4px;
    }
    .saved-searches-text {
      width: auto;
      line-height: 30px;
      color: rgba(255, 255, 255, 0.7);
      &:hover {
        color: white;
      }
    }
  }
}

.dd-footer {
  padding-top: 8px;
  color: $secondary;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  &:hover {
    color: maroon;
  }
}
