h1 {
  color: #ffc738 !important;
  font-weight: 500 !important;
}

ul#account-tabs {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin: 0 0 8px 0;
    padding: 0;

    a {
      margin: 0;
      padding: 8px 14px;
      display: block;
      border-radius: 2px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: #1b1c1d;
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      text-decoration: none;

      &.active {
        color: #1f1c1c;
        background-color: #fcc536;
        box-shadow: none;
      }
    }
  }
}

div#account-content {
  border-radius: 4px;
  background-color: #1b1c1d;
  overflow: hidden;
  padding: 10px 20px 20px 20px;

  h4 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.26px;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }
}
