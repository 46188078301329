.bc-loader {
  position: relative;
  width: 64px;
  height: 64px;
  div {
    position: absolute;
    border: 4px solid #586b7c;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.fs-spinner-container-bg {
  &.fs-spinner-container-bg-show {
    opacity: 1;
    transition: 1000ms opacity;
  }

  &.fs-spinner-container-bg-hide {
    opacity: 0;
  }

  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.84);
  z-index: 1000;
  .fs-info-text {
    color: #888;
    font-size: 85%;
  }
}
