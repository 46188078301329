@import "../../assets/styles/include";

$navbarheight: 129px;
$mobilefooterheight: 70px;
$pageheight: calc(100% - #{$height-navandfilter-desktop)};
$pageheightMobile: calc(100% - 68px);
$leftpanewidth: 300px;
$rightpanewidth: 300px;
$panemenuwidth: 70px;

.sidebar {
  top: 63px !important;
  border-right: 1px solid #666;
  background: #eee;
}

.map-pane {
  height: 100%;
  background: #888;
  position: relative;
  // margin-left: $panemenuwidth;
  margin-right: 0;
  transition: 300ms all;

  &.map-pane-left {
    //z-index: -100;
    margin-left: $leftpanewidth + $panemenuwidth;
    transition: 300ms all;

    @include tabletandsmaller() {
      margin-left: calc(100% - 40px);
    }
  }

  &.map-pane-right {
    //z-index: -100;
    // margin-right: $rightpanewidth;
    transition: 300ms all;
  }

  @include tabletandsmaller() {
    margin-left: 0;
  }
}

.pane-left {
  z-index: 500;
  background: #eee;
  margin-left: $panemenuwidth;
  width: $leftpanewidth;
  flex: 1 1 auto;
  height: $pageheight;
  position: absolute;
  overflow-y: auto;
  border-right: 1px solid #444;
  @include tabletandsmaller() {
    margin-left: 0;
    height: $pageheightMobile;
    width: 500px;
    max-width: calc(100% - 40px);
  }
}

.pane-right {
  background: white;
  width: 320px;
  //flex: 1 1 auto;
  height: $pageheight;
  position: absolute;
  top: $height-navandfilter-desktop;
  right: 0;
  overflow-y: auto;
  z-index: 1500;

  @include tabletandsmaller() {
    margin-left: 0;
    margin-top: 68px;
    top: 0;
    height: $pageheightMobile;
    width: 500px;
    max-width: 100%;
  }
  box-shadow: -5px -1px 20px 0px rgba(0, 0, 0, 0.1);
}

.bcshow-sright-true {
  .pane-right {
    border:1px solid red;
    opacity: 1;
    transition-delay: 200ms !important;
  }

}

.bcshow-sright-false {
  .pane-right {
    opacity: 0;
  }
}

.bcshow-sleft-true {
  .pane-right {
    border: 1px solid red;
    right:0;
    opacity: 1;
    transition-delay: 500ms !important;
  }
}

.bcshow-sleft-false {
  .pane-right {
    right:-100px;
    opacity: 0;
    transition-delay: 500ms !important;
  }
}

.bcshow-menu2-true {
  z-index:1500;
  right: 0;
  height: 100vh;
  width: 100%;
  top: 0;
  opacity: 1;

  border:1px solid red;

  transform-origin: top;
  position: absolute !important;
}

.bcshow-menu2-false {


  right: 0;
  height: 100vh;
  width: 100%;
  top: 0;

  opacity: 0;


  display: inline-block;
  position: absolute !important;
}

