@import "../../assets/styles/include";
$neutral: #f9f9f9;

.filterdropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-size: 14px;
  // font-weight: 500;
  width: auto;
  height: 32px;
  // background: #1b1c1d;
  border-radius: 2px;
  cursor: pointer;
  padding: 4px 8px;
  color: rgba(255, 255, 255, 1);

  &:hover {
    color: #ffc738;
    background-color: #1b1c1d;
  }

  &.fdd-selected {
    background: #ffc738;
    color: black;
    .fdd-icon {
      color: black;
    }
  }

  &.fdd-highlight {
    //
  }

  .fdd-label,
  .fdd-icon {
    //color: rgba(255, 255, 255, 0.7);
  }
  .fdd-icon {
    font-size: 16px;
    margin-right: 8px;
    // color: #ffc738;
  }
  .fdd-content {
    display: block;
    position: absolute;
    background: $neutral;
    z-index: 2300;
    padding: 16px;
    width: 300px;
    top: 24px;
    left: -40px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);

    @include tabletandsmaller() {
      width: 240px;
    }
  }
}

@include tabletandsmaller() {
  .filterdropdown:nth-of-type(n + 4) .fdd-content {
    left: auto;
    right: -28px;
  }
}

.bcshow-menu-true {
  z-index: 2300;
  opacity: 1;
  display: inline-block;
  transform: scaleY(1);
  margin-top: 1px;

  transform-origin: top;
  position: absolute !important;
}

.bcshow-menu-false {
  z-index: 2300;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;

  margin-top: 23px;
  display: inline-block;
  position: absolute !important;
}
