@import "./include";

$headerBackground: $secondary;
$headerColor: #000;
$borderRadius: 1px;

@keyframes modalFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modal-wrapper,
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal-overlay {
  z-index: 1300;
  background-color: rgba(black, 0.4);
}

.modal-wrapper {
  animation: modalFadeIn 0.4s ease;
  z-index: 1301;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-box {
    border-radius: $borderRadius;
    position: relative;
    display: flex;
    flex-flow: column;
    z-index: 1302;
    background: #ffffff;
    min-width: 500px;
    max-width: 500px;
    box-shadow: 1px 5px 20px 0 rgba(0, 0, 0, 0.2);
    max-height: 90vh;
    @media screen and (max-width: 800px) {
      min-width: 50%;
    }

    .modal-header {
      border-radius: $borderRadius $borderRadius 0 0;
      background: $headerBackground;
      //padding: 16px;

      .modal-header-content {
        justify-content: space-between;
        color: $headerColor;
        font-size: 16px;
        font-weight: bold;
        line-height: 23px;
        display: flex;
        flex-flow: row;
        padding: 8px;
        .close {
          span {
            color: $headerColor;
            cursor: pointer;

            &:hover {
              opacity: 0.9;
            }
          }

          button{
            border: none;
            background: none;
            font-size: 18px;
            padding: 0;
            font-weight: 800;
          }
        }
      }
    }

    .modal-content {
      padding: 0;
      @media screen and (max-width: 600px) {
        padding: 8px;
      }
      overflow-y: hidden;
    }

    .modal-footer {
      margin-top: 16px;
    }
  }
}

@keyframes modalspinnerfadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fsspinner {
  to {
    transform: rotate(360deg);
  }
}


