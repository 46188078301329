$width: 200px;

.image-uploader {
  .dropzone-container {
    width: $width;
    border: 5px dotted lightgray;
    position: relative;
  }
  .avatar-placeholder {
      width: 100%;
      // clip-path: ellipse($width/2 $width/2 at 50% 50%);
  }
  .plus-icon {
    position: absolute;
    bottom: -10px;
    right: $width/16 * -1;
    background-color: palegreen;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    padding-top: 4px;
    font-size: 40px;
    color: black;
    font-weight: bold;
    cursor: pointer;
  }
}