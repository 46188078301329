@import "../../assets/styles/include";
.addresssearch {
  width: 100%;

  form {
    position: relative;

    .fa-search {
      position: absolute;
      top: 8px;
      left: 12px;
      color: #ffffff;
    }
  }

  .navbar-locationfield {
    .form-element-input {
      margin: 0;
    }
    .location-search-input {
      background-color: $primaryLight;
      border: none;
      color: white;
      height: 33px;
      font-size: 14px;
      padding: 0 0 0 40px;
      line-height: 1.3;
    }
    ::placeholder {
      color: white;
    }
  }

  .form-locationinputfield-dropdown {
    width: 100%;
    position: absolute;
    z-index: 1501;

    .suggestion-item {
      padding: 8px;
      text-align: left;
      background-color: #fff;
      cursor: pointer;
    }

    .suggestion-item--active {
      cursor: pointer;
      padding: 8px;
      background-color: #aaa;
    }
  }
}
