.map-overlay-bar {
  position: absolute;
  z-index: 1050;
  left: 54px;
  display: flex;
  flex-wrap: wrap;

  .filterdropdown {
    margin-top: 8px;
    background-color: #1b1c1d;

    &:hover {
      color: #ffc738;
      background-color: #1b1c1d;
    }

    &.fdd-selected {
      background: #ffc738;
      color: black;
      .fdd-icon {
        color: black;
      }
    }

  }
}
