$values: 2,4,8,16,24,32,48,64,96,128,256;

@each $v in $values {
  .mt-#{$v} {
    margin-top: #{$v}px;
  }

  .mb-#{$v} {
    margin-bottom: #{$v}px;
  }

  .pt-#{$v} {
    padding-top: #{$v}px;
  }

  .pb-#{$v} {
    padding-bottom: #{$v}px;
  }

}