@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");
@import "include";
@import "helpers/main";
@import "_cssvariables.scss";
@import "_buttons.scss";
@import "_forms.scss";
@import "_responsivemenu.scss";
@import "_modal.scss";
@import "_global.scss";

@import "inverted.scss";

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  color: $textDark;
}

body,
html {
  min-height: 100%;
  height: 100%;
}
h1,
h2,
h3 {
  color: $textDark;
  padding: 0;
  margin: 0;
}

a.link {
  color: blue;
  text-decoration: underline;
}

label {
  font-size: 13px;
}

.leaflet-div-icon {
  border: none !important;
  background: none !important;
}

$ringCol: rgba(252, 197, 54, 0.5);

.gps_ring {
  border: 0;
  width: 20px;
  height: 20px;
  animation: pulse 1s infinite;
  box-shadow: 0 0 0 $ringCol;
  border-radius: 50%;
  img {
    width: 20px;
    height: 20px;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 $ringCol;
  }
  70% {
    box-shadow: 0 0 0 7px $ringCol;
  }
  100% {
    box-shadow: 0 0 0 0 $ringCol;
  }
}
