@import "../../assets/styles/include";

.navbar {
  position: relative;
  min-height: 67px;

  a {
    text-decoration: none;
    font-weight: 500;
  }
  background: $primary;

  .navbar-myaccount {
    position: relative;
    top: 3px;
  }

  .navbar-left {
    padding: 17px 0;
    position: relative;

    .navbar-logo {
      position: absolute;
      top: 13px;
      left: 15px;
      display: inline-block;
    }

    .navbar-addresssearch {
      padding: 0 0 0 85px;

      .form-element {
        margin: 0;
      }

      // @media screen and (max-width: 1080px) {
      //   padding: 0;
      //   margin: 0;
      //   width: 100% !important;
      //   background: red;
      // }
    }
  }
}

.menu-icon {
  margin-right: 8px;
  font-size: 20px;
  top: -2px;
  position: relative;
}
