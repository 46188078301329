@import "_include";

$menuLinkColor: #ffffff;
$menuLinkHoverColor: #eee;
$menuLinkActiveColor: #FFF;
$subMenuLinkColor: #eee;
$subMenuLinkHoverColor: white;


$backgroundColorSideBar: #000000;
$menuLinkColorSideBar: #fff;
$menuLinkHoverColorSideBar: #aaa;
$menuLinkActiveColorSideBar: #fdc537;
$subMenuLinkColorSideBar: #eee;
$subMenuLinkHoverColorSideBar: white;


$burgerColor: #ffffff;
$hamburgerOffsetTop: 24px;
$hamburgerOffsetRight: 15px;

.navbar-responsive {
  min-height: 100%;
  display: flex;

  ul {
    list-style-type: none;
    padding: 0;
    margin-left: auto;

    li {
      a {
          color: $menuLinkColor;

        &:hover {
          color: $menuLinkHoverColor;
        }

        &.active {
          color: $menuLinkActiveColor;
        }
      }
    }

    .menu-icon{
      margin-right: 8px;
    }

    @include tabletandsmaller() {
      li {
        a {
          color: $menuLinkColorSideBar;

          &:hover {
            color: $menuLinkHoverColorSideBar;
          }

          &.active {
            color: $menuLinkActiveColorSideBar;
          }
        }
      }
    }
  }

  &__list {
    display: flex;
  }

  &__list-item {
    position: relative;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;

    a,
    .submenu-item {
      color: $menuLinkColor;
      padding: 8px 16px;
      display: inline-flex;
      text-decoration: none;
    }

    .submenu-item {
      &:hover {
        color: $subMenuLinkHoverColor;
      }

      //position: relative;
      cursor: pointer;

      svg {
        margin-left: 4px;
      }
    }

    .submenu-items {
      min-width: 100%;
      width: auto;
      position: absolute;
      left: 0;
      top: 60px;
      background: $backgroundColorSideBar;
      border-color: $backgroundColorSideBar;

      li.navbar-responsive__list-item {
        display: block;

        a {
          color: $subMenuLinkColor;

          &:hover {
            color: $subMenuLinkHoverColor;
          }

          &.active {
            color: $menuLinkActiveColor;
          }
        }
      }
    }
  }
}

.bm-item-list,
.bm-item {
  &:focus {
    outline: none;
  }
}

.bm-wrapper-left {
  .bm-menu-wrap {
    left: 0;
  }

  .bm-burger-button {
    left: 10px;
  }
}

.navbar-mobile {
  ul.menu-items {
    li.navbar-responsive__list-item {
      display: block;
      text-align: left;
    }
  }
}

.navbar-mobile {
  ul.submenu-items {
    position: static;
    padding-left: 16px;
  }
}

.submenu-items {
  border: 1px solid;

  /* optional */
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;

  li.navbar-responsive__list-item {
    text-align: left;
    min-width: 150px;
  }

  z-index: 999;
}

/** hamburger-button **/
.bm-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.bm-burger-button {
  position: absolute;
  width: 28px;
  height: 20px;
  right: $hamburgerOffsetRight;
  top: $hamburgerOffsetTop;
}

.bm-cross-button {
  height: 24px;
  width: 24px;

  button {
    color: #000000;
  }
}

div.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  background: $backgroundColorSideBar;
}

.bm-morph-shape {
  fill: $backgroundColorSideBar;
}

.bm-burger-bars {
  background: $burgerColor;
}

.bm-cross {
  background: $burgerColor;
}

.bm-menu {
  background: $burgerColor;
}

/** needed to fix to get full width and height for overlay **/
.bm-overlay {
  top: 0;
  left: 0;
  z-index: 1900 !important;
}

/** needed to fix extra space at the top **/
.bm-menu-wrap {
  top: 0;
  z-index: 2001 !important;
}
