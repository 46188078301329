$form-border-color: #979797;
$form-border-color-error: #c01405;
$form-text-color: #555;
$form-font-size: 16px;
$form-font-size-error: 12px;
$form-element-bgcolor: rgba(255, 255, 255, 0.8);
$form-element-marginbottom: 20px;
$form-element-height: 56px;
$form-helpertext-color: #eeeeee;
$form-link-color: #000092;

.form-element {
  margin-bottom: $form-element-marginbottom;

  .form-element-label {
    font-size: $form-font-size;
    margin-bottom: 4px;

    label {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .form-element-helpertext {
    margin-top: 2px;
    font-size: 10px;
    color: $form-helpertext-color;
  }

  .form-element-input {
    margin-bottom: 4px;
    position: relative;

    input,
    textarea {
      border-radius: 4px;
      width: 100%;
      height: $form-element-height;
      line-height: $form-element-height;
      background-color: $form-element-bgcolor;
      border: 1px solid $form-border-color;
      font-size: $form-font-size;
      color: $form-text-color;
      padding-left: 20px;
      padding-right: 20px;
      caret-color: $form-text-color;
    }

    textarea {
      min-width: 100%;
      max-width: 100%;
      height: 128px;
      min-height: 128px;
    }
  }

  .error {
    input {
      border: 1px solid $form-border-color-error;
    }
  }

  .form-element-error {

    font-size: 15px;
    margin-bottom: 4px;
    color: #ffbdac;
  }

  .form-element-form-error {
    font-size: $form-font-size;
    margin-bottom: 4px;
    color: $form-border-color-error;
  }

  .form-link {
    text-decoration: none;
    // color: $form-link-color;
  }
}

/** checkbox styling */
.form-element.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  
  * {
    cursor: pointer;
  }

  .form-element-label {
    margin-bottom: 0;
  }

  input.checkbox,
  label {
    display: inline-block;
  }

  input.checkbox {
    // margin-right: 8px;
    width: auto;
    height: 16.5px;
  }
  input.checkbox,
  label {
    display: inline;
  }

  input.checkbox {
    position: relative;
    height: 25px;
    width: 25px;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 1;
  }

  span.checkbox {
    position: absolute;
    top: 6px;
    left: 0;
    z-index: 0;
    height: 16px;
    width: 16px;
    background-color: $form-element-bgcolor;
    border: 2px solid;
    border-color: $form-border-color;
  }

  span.checkbox:after {
    content: "";
    position: absolute;
    display: none;
  }

  input.checkbox:checked ~ span.checked:after {
    display: block;
  }

  input.checkbox:checked ~ span.checkbox {
    background-color: #fcc536;
    border-color: #fcc536;
  }

  span.checkbox:after {
    left: 3px;
    top: 0px;
    width: 3px;
    height: 7px;
    border: solid $primary;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
/** End of checkbox styling **/

.form-button {
  button {
    @extend .btn;
    @extend .btn-primary;
    font-weight: 500 !important;
    height: 48px !important;
    background-color: #ffc738;
    border-radius: 26px;
  }
}

.form-radio-element {
  display: flex;
  align-items: center;
  cursor: pointer;
  input {
    width: inherit !important;
  }

  label {
    margin-left: 8px;
  }
}

input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input[type="radio"] + .form-element-label {
  margin-top: 6px;
  margin-bottom: 6px;
}
input[type="radio"]:checked + .form-element-label label,
input[type="radio"]:not(:checked) + .form-element-label label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}
input[type="radio"]:checked + .form-element-label label:before,
input[type="radio"]:not(:checked) + .form-element-label label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    border: solid 2px #979797;
    border-radius: 100%;
    background: #fff;
}
input[type="radio"]:checked + .form-element-label label:after,
input[type="radio"]:not(:checked) + .form-element-label label:after {
    content: '';
    width: 6px;
    height: 6px;
    background: #252525;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
input[type="radio"]:checked + .form-element-label label:before {
  background-color: #fcc536;
  border-color: #fcc536;
}

input[type="radio"]:not(:checked) + .form-element-label label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
input[type="radio"]:checked + .form-element-label label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

$yellow: #ffc738;

.noUi-target {
    margin: 0 20px;
    height: 8px !important;;
    border: none !important;
    background-color: #D8D8D8 !important;

    .noUi-base {
        background-color: #d8d8d8;
        height: 8px;
        border-radius: 2px;

        .noUi-connects {
          .noUi-connect {
            background-color: $yellow;
          }
        }

        .noUi-origin {
          .noUi-handle {
            border: none;
            border-radius: 50%;
            background-color: $yellow;
            width: 24px;
            height: 24px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
            top: -8px;

            &:before,
            &:after {
                display: none !important;
            }
        }
        }
    }
}

.dark-bg{
  min-height: 100vh;
  background: #333333;
}

.narrow-form {
  min-height: 100vh;
  background: #333333;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  label {
    color: #ffffff;
  }

  a {
    color: #efac1f;

    &:hover {
      color: #fcc536;
    }
  }

  form {
    text-align: left;
    width: 310px;
    margin: 0 auto;

    button {
      width: 100%;
      border-radius: 26px;
      background-color: #f4c655 !important;
      font-weight: bold;
    }
  }

  .header {
    background: #262626;

    h1 {
      color: #fcc536;
      span {
        color: #ffffff;
        font-weight: 300;
      }
    }
  }
}
